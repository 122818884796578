import { Image } from "react-grid-gallery";

import Image1 from './assets/gallery/1.jpeg'
import Image2 from './assets/gallery/2.jpeg'
import Image3 from './assets/gallery/3.jpeg'
import Image4 from './assets/gallery/4.jpeg'
import Image5 from './assets/gallery/5.jpeg'
import Image6 from './assets/gallery/6.jpeg'
import Image7 from './assets/gallery/7.jpeg'
import Image8 from './assets/gallery/8.jpeg'
import Image9 from './assets/gallery/9.jpg'
import Image10 from './assets/gallery/10.jpg'

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: Image1,
    width: 1200,
    height: 800
  },
  {
    src: Image2,
    width: 1200,
    height: 800
  },
  {
    src: Image3,
    width: 1200,
    height: 800
  },
  {
    src: Image4,
    width: 800,
    height: 1100
  },
  {
    src: Image5,
    width: 800,
    height: 1100
  },
  {
    src: Image6,
    width: 1200,
    height: 800
  },
  {
    src: Image7,
    width: 800,
    height: 1000
  },
    {
    src: Image8,
    width: 800,
    height: 1100
  },
  {
    src: Image9,
    width: 1500,
    height: 1700
  },
  {
    src: Image10,
    width: 1500,
    height: 1700
  }
];
