import React, { Component } from 'react'
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import {Container, Navbar, Nav} from 'react-bootstrap'

//import { Button } from "rsuite";
  
// Import the default CSS
//import "rsuite/dist/rsuite.min.css";

import Button from '@mui/material/Button';

import logo from './assets/logo_circle.png'
import headerBkg from './assets/header-bkg.jpg'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

import Home from '../pages/Home.js'
import Contacts from '../pages/Contacts.js'
import Gallery from '../pages/Gallery.js'
import TravellinePlugin from "./TravellinePlugin";

export default class Header extends Component {
	render() {
		return (
			<>
				<div className="head-top">
					<Navbar expand="md" className="navbar navbar-dark navbar-expand-sm navbar-custom">
						<Container className="navBarTop">
							<Container>
								<Navbar.Brand href="/">
									<img
										src={logo}
										height="100"
										width="200"
										className="d-inline-block align-top"
										alt="Deluxe Aparts Logo" />
								</Navbar.Brand>
								<div className="hotelName">Deluxe Aparts</div>
								<div className="hotelDescription">АПАРТАМЕНТЫ СЕВАСТОПОЛЬ КРЫМ</div>
							
							<Navbar.Toggle className="custom-toggler" area-controls="responsive-nav-bar" />
							<Navbar.Collapse id="responsive-nav-bar" className="navBarTopButtons">
								<Nav className="mr-auto">
									<Nav.Link href="/contacts"> КОНТАКТ </Nav.Link>
									<Nav.Link href="/gallery"> ГАЛЕРЕЯ </Nav.Link>
								</Nav>
							</Navbar.Collapse>	
							</Container>
						</Container>
					</Navbar>

					<Container className="navBarBottom">
				      <Row>
				        <Col sm={8} id="navBarBottomLeft">
				        	<Row>
				        	<div className="app">
				        		СТИЛЬНЫЕ АПАРТАМЕНТЫ У МОРЯ
				        	</div>
				        	</Row>
				        	<Row><p></p></Row>
				        	<Row>
				        	<div className="description"> Апартаменты Deluxe Aparts находятся в комплексе Aqua Deluxe в городе Севастополе в 100 метрах от моря с оборудованным пляжем у парка Победы. В СПА Aqua Deluxe входят 10 бань народов мира, бассейн, купели, а также открытый бассейн с тёплой морской водой. 
				        	<br/>Территория ухоженная, где в летнее время проводятся анимации. В комплексе есть магазин, кафе и ресторан. В пешей доступности имеется набережная с кафе и ресторанами на любой вкус.
				        	<br/>В каждом апартаменте есть балкон с видом на бассейн и море, также апартаменты оснащены кухнями, собственными ванными комнатами,  двухспальными кроватями с ортопедическиии матрасами и гипоаллергенным бельём. Шторы блэкаут не позволят нарушить ваш сон ранним утром.
				        	<br/>В стоимость апартаментов входит пользование открытым бассейном и пляжем с лежаками и матрасами. Также имеется скидка 35% на СПА комплекс.
							</div>
							</Row>

				        </Col>
				        <Col id="navBarBottomRight"></Col>
				      </Row>
				    </Container>
					<TravellinePlugin />
				</div>

				<Router>
					<Routes>
						<Route exact path="/" element={<Home />} />
						<Route exact path="/contacts" element={<Contacts />} />
						<Route exact path="/gallery" element={<Gallery />} />
					</Routes>
				</Router>
			</>
			)
	}
}