import React, { Component } from 'react';
import CarouselBox from "../components/CarouselBox.js";
import ImageHomeGridGallery  from "../components/ImageHomeGridGallery.js";
import ContactUs  from "../components/ContactUs.js";
import Footer  from "../components/Footer.js";
import Description  from "../components/Description.js";
import {Container} from 'react-bootstrap'

export default class Home extends Component {
	render() {
		return (
			<div>
				<div id="imageGallery"><ImageHomeGridGallery /></div>
				<div id="contactUs"><ContactUs /></div>
				<div id="footer"><Footer /></div>
			</div>
			)
	}
}