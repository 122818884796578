import React, { Component } from 'react';
import ImageHomeGridGallery  from "../components/ImageHomeGridGallery.js";
import ImageRoom232  from "../components/ImageRoom232.js";
import ImageRoom232a  from "../components/ImageRoom232a.js";
import Footer  from "../components/Footer.js";

export default class Gallery extends Component {
	render() {
		return (
			<div>
				<div id="imageGallery"><ImageHomeGridGallery /></div>
				<div className="roomName" align="center">Четырехместный полулюкс 232</div>
				<div id="imageGallery"><ImageRoom232/></div>
				<div className="roomName" align="center">Четырехместный полулюкс 232а</div>
				<div id="imageGallery"><ImageRoom232a /></div>
				<div id="footer"><Footer /></div>
			</div>
			)
	}
}