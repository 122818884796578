import React, { Component } from 'react'
import {Container} from 'react-bootstrap'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Map from "./Map.jsx"

export default class ContactUs extends Component {
	render() {
		return (
			<div>
				<Container className="contactUs">
				      <Row>
				        <Col  sm={6}>
				        	<p><br /></p>
				        	<p>улица Парковая 7 <br />
				        		Севастополь, Крым, Россия </p>
				        	<p> +79787619606 <br />
				        		+79117981055 (резервный)</p>
				        	<p> nataliya.kebkal@gmail.com </p>
				        	<p> Пн. – Вс. КРУГЛОСУТОЧНО </p>
				        </Col>
				        <Col ><iframe className="map" src="https://yandex.ru/map-widget/v1/org/deluxe_aparts/57879566767/?ll=33.462280%2C44.607941&z=16" frameborder="0"></iframe></Col>
				      </Row>				      
				    </Container>
			</div>
			// <CarouselBox />
			)
	}
}
