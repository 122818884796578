import React, { Component } from 'react'
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import {Container, Navbar, Nav} from 'react-bootstrap'
import Home from '../pages/Home.js'

export default class Footer extends Component {
	render() {
		return (
			<div className="contactUs">
					<Navbar expand="md" className="navbar navbar-dark navbar-expand-sm navbar-custom">
						<Container>
							<Container>
								<Nav.Link href="/" >Deluxe Aparts</Nav.Link>
							</Container>	
						</Container>
					</Navbar>
			</div>
			)
	}
}
